body,
html,
.mainLayout,
#root {
  height: 100vh;
  width: 100vw;
}

.logo {
  max-width: 100%;
  padding: 20px;
  max-height: 100px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  transition: color 0.3s;
}

.trigger:hover {
  color: #04A57D;
}

.innerLayout {
  background: #f2f3f5;
  overflow-y: overlay;
}

.top-header {
  padding: 0 20px 0 15px;
  margin: 0 0 2px 0;
  background: #ffffff;
}

.mainContent {
  margin: 20px;
}

.mainContentBG {
  background: #ffffff;
  border-radius: 4px;
}

.loginLogo {
  height: 60px;
  margin: 20px auto;
}

.loginCompanyLogo {
  height: 60px;
  display: block;
  margin: 0px auto 20px auto;
}

.loginbg {
  background: url(./img/loginbg.png);
  height: 100%;
  background-size: cover;
  display: flex;
  background-position: center;
  flex-direction: column;
}

.loginWrapper {
  margin: 10% auto auto auto;
  display: flex;
  flex-direction: column;
}

.loginContainer {
  background: #ffffff;
  width: 500px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 25px;
}

.loadingScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingPage {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
}

.summaryRow {
  background: #fafafa;
}

.modalScrollableContainer {
  overflow: auto;
  max-height: 500px;
}

.modalScrollableContainerSawmill {
  overflow: auto;
  max-height: 450px;
  border: 1px solid #d9d9d9;
}

.ant-statistic {
  text-align: center;
}

.contentCard {
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.positionBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.loginMobile {
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  color: #222222;
}

.downloadimg {
  width: 100%;
  object-fit: contain;
}

.mobileLoginContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  width: 80%;
  height: 100%;
  transform: translateY(10%);
  margin: auto;
  padding: 20px;
}
